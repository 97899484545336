import { toast } from "react-toastify"
import { post } from "../utils/http_fetch"

const getCategories = async (setState)=>{
    await post({
        api: "web/show-categories",
        type: "json"
    })
    .then(data => {
        if(data?.success){            
            setState(data?.categories)
        }else {
           toast.error(data?.msg)
        }
    })
}

export default getCategories