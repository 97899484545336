import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import validation from '../../../validations/auth/register-validation'
import Button from '../../../components/elements/primary-button'
import { post } from "../../../utils/http_fetch"
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import logoLight from "../../../../public/mysave-light.webp"
import logoDark from "../../../../public/mysave-dark.webp"
import { useDarkMode } from '../../../stores'

export default function Register() {

  const { dark } = useDarkMode()

  const navigate = useNavigate()

  const register = values => {
    post({
      api: "web/auth/register",
      values,
      type: "json"
    })
    .then(data => {
      if(data?.success){
        toast.success("Erfolgreich Registriert")
        navigate("/auth/login")
      }else {
        toast.error(data?.msg)
      }
    })
  }

  return (
    <div className='w-full max-[1024px]:h-auto flex items-start justify-center px-[20px] pb-[50px]'>
      <div className='w-[350px] max-[1024px]:w-full mt-[30px]'>
        <img src={dark ? logoDark : logoLight} alt="logo" className='w-[150px] mx-auto mb-[20px]' />
        <p className='text-[28px] text-center font-[raleway] font-bold mt-[30px]'>Registrieren</p>
        <Formik
          validationSchema={validation}
          initialValues={{
            firstname: "",
            lastname: "",
            email: "",
            password: "",
            password2: ""
          }}
          onSubmit={register}
        >
          {({ dirty, isValid }) => (
          <Form>

            <Field type="text" placeholder='Vorname *' className='input' name='firstname' />
            <ErrorMessage component={"div"} className='text-black dark:text-white mt-[15px]' name='firstname' />

            <Field type="text" placeholder='Nachname *' className='input' name='lastname' />
            <ErrorMessage component={"div"} className='text-black dark:text-white mt-[15px]' name='lastname' />

            <Field type="text" placeholder='E-Mail-Adresse *' className='input' name='email' />
            <ErrorMessage component={"div"} className='text-black dark:text-white mt-[15px]' name='email' />

            <Field type="password" placeholder='Passwort *' className='input' name='password' />
            <ErrorMessage component={"div"} className='text-black dark:text-white mt-[15px]' name='password' />

            <Field type="password" placeholder='Passwort wiederholen *' className='input' name='password2' />
            <ErrorMessage component={"div"} className='text-black dark:text-white mt-[15px]' name='password2' />

            <Button disabled={!dirty || !isValid} type="submit" className='mt-[20px] rounded'>Account erstellen</Button>

            <Link to={"/auth/login"} className='w-full h-[50px] bg-[whitesmoke] dark:bg-[rgba(0,0,0,0.3)] flex items-center justify-center mt-[15px] rounded'>
              Login
            </Link>

          </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
