import React, { useEffect, useState } from 'react'
import { IoClose, IoSearchOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import useSearch from '../../../../hooks/use-search'
import stringIsObject from '../../../../utils/string_is_object'

export default function SearchMobile({ searchVisible, setSearchVisible, query, setQuery, searchResults }) {

  const search = useSearch(query, setQuery, setSearchVisible)

  const [history, setHistory] = useState(stringIsObject(localStorage.getItem("search_history")))

  useEffect(()=>{
    setHistory(stringIsObject(localStorage.getItem("search_history")))
  }, [searchVisible])

  const resetHistory = ()=>{
    setHistory([])
    localStorage.removeItem("search_history")
  }

  return (
    <div className={`w-full h-full bg-white dark:bg-[#1c1c1c] fixed top-0 duration-300 ${ searchVisible ? 'right-0' : 'right-[-100%]' } z-[13] min-[1024px]:hidden overflow-y-auto p-[25px]`}>
        
        <div className='border-b border-[#ddd] dark:border-[dimgrey] mb-[30px] pb-[8px] flex items-center justify-between'>
            <p className='text-[30px]'>Suche</p>
            <button className='w-[40px] h-[40px] rounded-full bg-[whitesmoke] dark:bg-[#333333] flex items-center justify-center cursor-pointer duration-500 hover:bg-[var(--primary)] hover:text-white' aria-label="search" onClick={()=>setSearchVisible(false)}>
                <IoClose size={20} />
            </button>
        </div>
        
        <form className='w-full h-[45px] dark:bg-[rgba(0,0,0,0.4)] rounded-[5px] overflow-hidden flex items-center justify-between shadow-[0_0_8px_lightgrey] dark:shadow-[0_0_8px_rgba(0,0,0,0.2)]' onSubmit={search}>
            <input type="text" name='search-mobile' className='w-full h-full bg-transparent outline-none pl-[15px] placeholder:text-[dimgrey]' value={query} placeholder='Suche Halsketten ...' onChange={e => setQuery(e.target.value)} />
            <div className='h-full flex items-center justify-center'>
                <div className='w-[50px] h-full'>
                { query && (
                    <div className='w-full h-full cursor-pointer flex items-center justify-center' onClick={()=>setQuery("")}>
                        <IoClose size={23} />
                    </div>
                ) }
                </div>
                <button aria-label='search' type='submit' className='w-[80px] rounded-l-[5px] gap-x-[8px] h-full bg-[var(--primary)] flex items-center justify-center text-white'>
                    <IoSearchOutline size={25} />
                </button>
            </div>
        </form>

        { history.length > 0 && (
            <>
                <p className='mt-[20px] text-[20px]'>Verlauf</p>
                <div className='flex flex-wrap mt-[15px] gap-[12px]'>
                { history.map((_history, index)=>{
                        return (
                            <Link key={index} to={"/search?query=" + encodeURIComponent(_history)} className='px-[20px] max-w-[180px] text-ellipsis overflow-hidden whitespace-nowrap h-[40px] bg-[whitesmoke] duration-300 dark:bg-[#333333] rounded-full border border-[#ddd] dark:border-[dimgrey] text-center leading-[40px] hover:bg-[var(--primary)] hover:text-white' onClick={()=>setSearchVisible(false)}>{ _history }</Link>
                        )
                }) }
                </div>
                <button className='px-[15px] h-[40px] border border-[var(--primary)] hover:bg-[var(--primary)] hover:text-white duration-300 rounded-full text-[var(--primary)] mt-[15px]' onClick={resetHistory}>Alle Löschen</button>
            </>
        ) }

        { query.trim() && (
            <>


            <div className='mt-[20px] mb-[10px]'>Suche: "{query}"</div>

            { searchResults.length > 0 && (
                <div className='text-[20px] mb-[10px]'>Produkte</div>
            ) }

            { searchResults.map((result, index)=>{
                return (
                    <Link key={index} to={`/details/${ result.url }`}  className={`w-full h-[90px] border-b border-[#ddd] dark:border-[dimgrey] last:border-b-0 flex items-center justify-start gap-x-[15px] hover:bg-[rgba(0,0,0,0.03)]`} onClick={()=>{setQuery(""); setSearchVisible(false)}}>
                    <img src={"https://assets.adidas.com/images/h_840,f_auto,q_auto,fl_lossy,c_fill,g_auto/bf9cb1cf4d5541e984e9aefa00b6e59d_9366/Tiro_23_League_Hose_Schwarz_HS7232_21_model.jpg"} alt="resultImg" width={60} height={60} className='w-[60px] h-[60px] object-contain' loading='lazy' />
                    <div>
                      <p className='text-[18px]'>{ result.title }</p>
                      { result.sale_percent === "NO_SALE" ? (
                          <p className='text-[15px]'>{ result.price.toLocaleString("de-DE", { style: "currency", currency: "EUR" }) }</p>
                      ) : (
                          <>
                              <p className='flex items-center justify-start gap-x-[10px] text-[15px]'>
                                  <span className='line-through'>{ result.price.toLocaleString("de-DE", { style: "currency", currency: "EUR" }) }</span>
                                  <span className='text-[crimson]'>{ (result.price - (result.price * `0.${ parseFloat(result.sale_percent) <= 9 ? "0" + result.sale_percent : result.sale_percent }`)).toLocaleString("de-DE", { style: "currency", currency: "EUR" }) }</span>
                              </p>
                              <p className='text-[13px]'>{ result.sale_percent }% Rabatt</p>
                          </>
                      ) }
                    </div>
                  </Link>
                )
            }) }

            </>
        ) }

        <p className='mt-[20px] text-[20px]'>Am beliebsten</p>
        <div className='flex flex-wrap mt-[15px] gap-[12px]'>
            <Link to={"/search?query=ringe"} className='px-[20px] h-[40px] bg-[whitesmoke] dark:bg-[#333333] rounded-full border border-[#ddd] dark:border-[dimgrey] flex items-center justify-center duration-300 hover:bg-[var(--primary)] hover:text-white' onClick={()=>setSearchVisible(false)}>Ringe</Link>
            <Link to={"/search?query=geschenke"} className='px-[20px] h-[40px] bg-[whitesmoke] dark:bg-[#333333] rounded-full border border-[#ddd] dark:border-[dimgrey] flex items-center justify-center duration-300 hover:bg-[var(--primary)] hover:text-white' onClick={()=>setSearchVisible(false)}>Geschenke</Link>
            <Link to={"/search?query=armbaender"} className='px-[20px] h-[40px] bg-[whitesmoke] dark:bg-[#333333] rounded-full border border-[#ddd] dark:border-[dimgrey] flex items-center justify-center duration-300 hover:bg-[var(--primary)] hover:text-white' onClick={()=>setSearchVisible(false)}>Armbänder</Link>
        </div>

    </div>
  )
}
