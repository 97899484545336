import React, { useEffect } from 'react'
import Header from '../components/static/header'
import { Outlet, useLocation } from 'react-router-dom'
import { useAuth } from '../stores';
import Footer from '../components/static/footer';

export default function WebLayout() {

  const location = useLocation();
  const { getUserInfo } = useAuth();

  useEffect(()=>{
    getUserInfo();
  }, [])


  useEffect(()=>{
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <>
    
      <Header />

      <div className='mt-[150px] min-h-[calc(100vh-80px)] max-[1024px]:mt-[80px]'>
        <Outlet />
      </div>

      <Footer />

    </>
  )
}
