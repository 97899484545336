import CryptoJS from 'crypto-js';

const key = CryptoJS.enc.Hex.parse('0123456789abcdef0123456789abcdef');
const iv  = CryptoJS.enc.Hex.parse('abcdef9876543210abcdef9876543210');

const encrypt = (value)=>{

    const encrypted = CryptoJS.AES.encrypt(value, key, { iv: iv });

    const encrypted_data = encrypted.ciphertext.toString(CryptoJS.enc.Base64);

    return encrypted_data

}

const decrypt = (value)=>{

}

export {
    encrypt,
    decrypt
}