import React from 'react'
import { Link } from 'react-router-dom'
import necklace from "../../../../public/necklace.webp"
import bracelet from "../../../../public/bracelet.webp"

export default function Home() {
  return (
    <>

      <div className='w-full h-[350px] bg-gradient-to-r from-[whitesmoke] dark:from-[#333333] flex items-center justify-between relative overflow-hidden px-[8%] max-[1024px]:px-[20px] dark:text-white text-black'>
        <div className='leading-[1.1]'>
          <p className='text-[20px] font-[raleway-bold]'>ANGEBOTE</p>
          <p className='text-[20px] font-[raleway-bold]'><span className='font-[roboto-bold] text-[var(--primary)]'>20%</span> RABATT</p>
          <p className='text-[20px] font-[raleway-bold]'>AUF ALLES</p>
        </div>
      </div>
      
      {/* <div className='px-[8%] max-[1024px]:px-[20px]'>

        <div className='grid grid-cols-4 max-[1400px]:grid-cols-2 max-[600px]:grid-cols-1 gap-6 mt-[30px]'>
          
          <Link to="/collections/halsketten" className='w-full h-[360px] relative group overflow-hidden shadow-[0_0_10px_rgba(0,0,0,0.2)] rounded-[15px]'>
              <img src={necklace} alt="collection" className='w-full h-full object-cover object-top absolute top-0 left-0 duration-500 group-hover:scale-105 z-[-1]' />
              <div className='w-full h-full bg-gradient-to-t from-[rgba(0,0,0,0.7)] from-[50%] to-transparent relative'>
                <div className='absolute bottom-[10px] left-0 text-white p-[20px]'>
                  <p className='text-[35px] font-[raleway-bold]'>Halsketten</p>
                  <p>Entdecken Sie schöne Produkte</p>
                  <button className='px-[15px] h-[45px] cursor-pointer border border-white mt-[15px] rounded duration-300 group-hover:bg-white group-hover:text-black'>Jetzt Entdecken</button>
                </div>
              </div>
           </Link>

           <Link to="/collections/armbaender" className='w-full h-[360px] relative group overflow-hidden shadow-[0_0_10px_rgba(0,0,0,0.2)] rounded-[15px]'>
              <img src={bracelet} alt="collection" className='w-full h-full object-cover object-top absolute top-0 left-0 duration-500 group-hover:scale-105 z-[-1]' />
              <div className='w-full h-full bg-gradient-to-t from-[rgba(0,0,0,0.7)] from-[50%] to-transparent relative'>
                <div className='absolute bottom-[10px] left-0 text-white p-[20px]'>
                  <p className='text-[35px] font-[raleway-bold]'>Armbänder</p>
                  <p>Entdecken Sie schöne Produkte</p>
                  <button className='px-[15px] h-[45px] cursor-pointer border border-white mt-[15px] rounded duration-300 group-hover:bg-white group-hover:text-black'>Jetzt Entdecken</button>
                </div>
              </div>
            </Link>

            <Link to="/collections/ohrringe" className='w-full h-[360px] relative group overflow-hidden shadow-[0_0_10px_rgba(0,0,0,0.2)] rounded-[15px]'>
            <img src={bracelet} alt="collection" className='w-full h-full object-cover object-top absolute top-0 left-0 duration-500 group-hover:scale-105 z-[-1]' />
              <div className='w-full h-full bg-gradient-to-t from-[rgba(0,0,0,0.7)] from-[50%] to-transparent relative'>
                <div className='absolute bottom-[10px] left-0 text-white p-[20px]'>
                  <p className='text-[35px] font-[raleway-bold]'>Ohrringe</p>
                  <p>Entdecken Sie schöne Produkte</p>
                  <button className='px-[15px] h-[45px] cursor-pointer border border-white mt-[15px] rounded duration-300 group-hover:bg-white group-hover:text-black'>Jetzt Entdecken</button>
                </div>
              </div>
            </Link>
          
            <Link to="/collections/ringe" className='w-full h-[360px] relative group overflow-hidden shadow-[0_0_10px_rgba(0,0,0,0.2)] rounded-[15px]'>
              <img src={bracelet} alt="collection" className='w-full h-full object-cover object-top absolute top-0 left-0 duration-500 group-hover:scale-105 z-[-1]' />
              <div className='w-full h-full bg-gradient-to-t from-[rgba(0,0,0,0.7)] from-[50%] to-transparent relative'>
                <div className='absolute bottom-[10px] left-0 text-white p-[20px]'>
                  <p className='text-[35px] font-[raleway-bold]'>Ringe</p>
                  <p>Entdecken Sie schöne Produkte</p>
                  <button className='px-[15px] h-[45px] cursor-pointer border border-white mt-[15px] rounded duration-300 group-hover:bg-white group-hover:text-black'>Jetzt Entdecken</button>
                </div>
              </div>
            </Link>
            
        </div>
        
      </div> */}

    </>
  )
}
