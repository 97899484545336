import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { post } from "../../../utils/http_fetch"
import Product from '../../../components/elements/product';
import useSearch from '../../../hooks/use-search';
import { IoSearchOutline, IoClose, IoFilter, IoFilterCircleOutline, IoAlertCircleOutline } from "react-icons/io5"
import ProductFilter from '../../../components/elements/product-filter';

export default function Search() {

  const location = useLocation();
  const navigate = useNavigate();
  
  const [loading, setLoading] = useState(true)
  const [products, setProducts] = useState([])
  const [productCount, setProductCount] = useState(0)
  const [inStock, setInStock] = useState(false);
  const [notInStock, setNotInStock] = useState(false);

  const [filterStats, setFilterStats] = useState({
    in_stock: 0,
    not_in_stock: 0
  })

  const [productFilterVisible, setProductFilterVisible] = useState(false);
  const [nextPage, setNextPage] = useState(false)

  const [query404, setQuery404] = useState("");

  const search = useSearch(query404, setQuery404);

  const params = new URLSearchParams(location.search)
 
  const currentQuery = params?.get("query") || "";
  const currentPage = parseInt(params?.get("page")) || 1;


  useEffect(()=>{  
      showProducts();
  }, [currentQuery, currentPage, location, inStock, notInStock])

  const showProducts = ()=>{

    setLoading(true);

    post({
      api: "web/search-results",
      values: {
        query: currentQuery,
        page: currentPage <= 0 ? 1 : currentPage,
        inStock: inStock,
        notInStock: notInStock
      }
    })
    .then(data => {
      if(data?.success){
        setProducts(data.results);
        setProductCount(data.product_count);
        setFilterStats(data.filter_stats)
        setNextPage(data.next_page);
      }
    })
    .finally(()=>{
      setLoading(false)
    })
  }

  return (
    <>
      <div className='w-full inline-block mt-[30px] px-[8%] max-[1024px]:px-[20px] pb-[30px]'>
          { (!loading && products.length > 0) && (
            <>
            
            <div className='w-full h-[60px] border-b border-b-[#ddd] dark:border-[dimgrey] flex items-center justify-between'>
              <div className='flex items-center justify-start gap-x-[15px]'>
                <p className='max-w-[400px] max-[1024px]:max-w-[300px] overflow-hidden text-ellipsis text-[23px] uppercase font-[roboto-bold]'>{ currentQuery === "" ? "Alle Produkte" : "\"" + currentQuery +  "\"" }</p>
                <p>[ { productCount } ]</p>
              </div>
              <div className='flex items-center justify-center'>
                <button className='flex items-center justify-center gap-x-[10px] bg-[whitesmoke] dark:bg-[#333333] px-[15px] h-[40px] rounded' onClick={()=>setProductFilterVisible(true)}>
                  <IoFilterCircleOutline size={25} />
                  <p className='max-[550px]:hidden'>Filtern</p>
                </button>
              </div>
            </div>
            <div className='w-full grid grid-cols-4 max-[1300px]:grid-cols-3 max-[900px]:grid-cols-2 max-[650px]:grid-cols-1 items-center justify-center mt-[30px] gap-2 max-[500px]:gap-4'>
                { products.map((product, index)=>{
                    return (
                        <Product 
                        key={index}
                        product={product} 
                        />
                    )
                }) }
            </div>
            <div className='w-full flex items-center justify-between mt-[20px]'>
                  <p>Seite: { currentPage <= 0 ? 1 : currentPage } von { Math.ceil(productCount / 28) }</p>
                  <div className='h-[45px] flex items-center justify-center border border-[#ddd] dark:border-[dimgrey] rounded overflow-hidden'>
                    <button disabled={currentPage <= 1} className='w-[80px] h-full border-r border-[#ddd] dark:border-[dimgrey] bg-[whitesmoke] dark:bg-[rgba(0,0,0,0.2)] flex items-center justify-center border-[#ddd] dark:border-[dimgrey] cursor-pointer hover:bg-[var(--primary)] hover:text-white duration-300 disabled:opacity-30 disabled:cursor-not-allowed' onClick={()=>navigate(`/search?q=${ currentQuery }&page=${ parseInt(currentPage) - 1 }`)}>
                      Zurück
                    </button>
                    <button disabled={!nextPage} className='w-[80px] h-full bg-[whitesmoke] dark:bg-[rgba(0,0,0,0.2)] flex items-center justify-center cursor-pointer hover:bg-[var(--primary)] hover:text-white duration-300 disabled:opacity-30 disabled:cursor-not-allowed' onClick={()=>navigate(`/search?q=${ currentQuery }&page=${ parseInt(currentPage) + 1 }`)}>
                      Weiter
                    </button>
                  </div>
            </div>
            
            </>
          ) }

          { (!loading && products.length === 0) && (
             <div className='w-full px-[20px] min-h-[calc(100vh-90px)] max-w-[1200px] mx-auto flex flex-col text-center items-center justify-center'>
                <IoSearchOutline size={80} />
                <p className='mt-[10px] text-[20px]'>Leider haben wir ihr gesuchtes Produkt nicht gefunden.</p>
                <p className='mt-[5px]'>Suchen Sie bitte Weiter.</p>
              </div>
          ) }

          {/* { !loading && products.length === 0 && (
            <>
            
            <div className='w-full border-b border-b-[#ddd] pb-[15px] dark:border-[dimgrey] flex flex-col'>
                <p className='w-full text-[23px] uppercase font-[roboto-bold] break-words'>HOPPA KEINE SUCHERGEBNISSE FÜR "{ currentQuery }"</p>

                <form className='w-full max-w-[550px] h-[45px] dark:bg-[rgba(0,0,0,0.4)] rounded-[5px] overflow-hidden flex items-center justify-between shadow-[0_0_8px_lightgrey] dark:shadow-[0_0_8px_rgba(0,0,0,0.2)] mt-[15px]' onSubmit={search}>
                  <input type="text" className='w-full h-full bg-transparent outline-none pl-[15px] placeholder:text-[dimgrey]' value={query404} placeholder='Suche ...' onChange={e => setQuery404(e.target.value)} />
                  <div className='h-full flex items-center justify-center'>
                    { query404 && (
                        <div className='px-[15px] cursor-pointer' onClick={()=>setQuery404("")}>
                            <IoClose size={23} />
                        </div>
                      ) }
                    <button type='submit' className='px-[15px] rounded-l-[5px] gap-x-[8px] h-full bg-[var(--primary)] flex items-center justify-center text-white'>
                        <IoSearchOutline size={25} />
                        <p className='mt-[2px]'>Suchen</p>
                    </button>
                  </div>
              </form>

            </div>
            
            </>
          ) } */}

      </div>

      <ProductFilter
        productFilterVisible={productFilterVisible}
        setProductFilterVisible={setProductFilterVisible}
        filterStats={filterStats}
        inStock={inStock}
        setInStock={setInStock}
        notInStock={notInStock}
        setNotInStock={setNotInStock}
      />

    </>
  )
}
