import * as Yup from "yup"

const validation = Yup.object().shape({
    firstname: Yup.string().trim().required("* Bitte geben Sie Ihren Vornamen ein."),
    lastname: Yup.string().trim().required("* Bitte geben Sie Ihren Nachnamen ein."),
    email: Yup.string().trim().email("* Bitte eine gültige E-Mail-Adresse eingeben.").required("* Bitte geben Sie Ihre E-Mail-Adresse ein."),
    password: Yup.string().trim().min(8, '* Das Passwort muss mindestens 8 Zeichen lang sein').matches(/[A-Z]/, 'Das Passwort muss mindestens einen Großbuchstaben enthalten').matches(/[!@#$%^&*()_+{}\[\]:;<>,.?~\\\-]/, '* Das Passwort muss mindestens ein Sonderzeichen enthalten').required("* Bitte geben Sie ihr Passwort ein."),
    password2: Yup.string().trim().required("* Bitte wiederholen Sie ihr Passwort").oneOf([Yup.ref("password"), null], "* Die beiden Passwörter müssen übereinstimmen"),
})

export default validation