import React from 'react'
import ModalHeader from '../../../components/elements/modal-header'
import ModalButtons from '../../../components/elements/modal-buttons'
import { usePrimary } from '../../../stores'

export default function PrimarySwitcherModal({ close }) {

  const { setPrimary } = usePrimary()

  return (
    <div className='w-[550px] max-[570px]:w-[90%] bg-white dark:bg-[#1c1c1c] rounded-[10px] overflow-hidden'>
        <ModalHeader 
            title={"Farbe ändern"} 
        />
        <div className='py-[30px] flex items-center justify-center gap-x-[12px]'>
            <div className='w-[30px] h-[30px] bg-[#dd00b8] duration-300 rounded-full cursor-pointer hover:shadow-[0_0_0_2px_white,0_0_0_4px_#dd00b8] dark:hover:shadow-[0_0_0_2px_#1c1c1c,0_0_0_4px_#dd00b8]' onClick={()=>setPrimary("#dd00b8")}></div>
            <div className='w-[30px] h-[30px] bg-[dodgerblue] duration-300 rounded-full cursor-pointer hover:shadow-[0_0_0_2px_white,0_0_0_4px_dodgerblue] dark:hover:shadow-[0_0_0_2px_#1c1c1c,0_0_0_4px_dodgerblue]' onClick={()=>setPrimary("dodgerblue")}></div>
            <div className='w-[30px] h-[30px] bg-[darkorange] duration-300 rounded-full cursor-pointer hover:shadow-[0_0_0_2px_white,0_0_0_4px_darkorange] dark:hover:shadow-[0_0_0_2px_#1c1c1c,0_0_0_4px_darkorange]' onClick={()=>setPrimary("darkorange")}></div>
            <div className='w-[30px] h-[30px] bg-[#2b805a] duration-300 rounded-full cursor-pointer hover:shadow-[0_0_0_2px_white,0_0_0_4px_#2b805a] dark:hover:shadow-[0_0_0_2px_#1c1c1c,0_0_0_4px_#2b805a]' onClick={()=>setPrimary("#2b805a")}></div>
        </div>
        <div className='p-[20px]'>
            <ModalButtons />
        </div>
    </div>
  )
}
