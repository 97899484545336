import { post } from "../utils/http_fetch";

// search autocomplete

const getSearchResults = async (query, setState)=>{
    await post({
        api: "web/search-autocomplete",
        values: {
            query: query
        }
    })
    .then(data => {
        if(data?.success){
            setState(data.results);
        }
    })
}

export default getSearchResults;