import React from 'react'
import { useNavigate } from 'react-router-dom'
import stringIsObject from '../utils/string_is_object'

export default function useSearch(query, setQuery, setSearchVisible = false) {
  
    const navigate = useNavigate()

    const search = (e)=>{

        e.preventDefault()

        navigate(`/search?query=${ encodeURIComponent(query) }`)

        let history = stringIsObject(localStorage.getItem("search_history"))

        if(query.trim()){
            history = [query, ...history]
            localStorage.setItem("search_history", JSON.stringify(history))    
        }

        setQuery("")

        if(setSearchVisible){
            setSearchVisible(false)
        }

    }

    return search

}
