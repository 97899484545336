import { createBrowserRouter } from "react-router-dom";
import WebLayout from "../layouts"
import Home from "../pages/main/home"
import TestProducts from "../pages/main/test-products"
import Sale from "../pages/main/sale"
import Bestseller from "../pages/main/bestseller"
import NewHits from "../pages/main/new-hits"
import Cart from "../pages/main/cart"
import CollectionLayout from "../layouts/collections";
import Collections from "../pages/main/collections"
import CollectionProducts from "../pages/main/collections/products"
import Search from "../pages/main/search"
import Details from "../pages/main/details"
import PaymentSuccess from "../pages/main/payment-success";
import PaymentCanceled from "../pages/main/payment-canceled";
import Profile from "../pages/main/profile"
import Login from "../pages/auth/login"
import Register from "../pages/auth/register"
import ForgotPassword from "../pages/auth/forgot-password"
import PageNotFound from "../pages/main/page-not-found"

const routes = createBrowserRouter([
    {
        path: "/",
        element: <WebLayout />,
        children: [
            {
                index: true,
                element: <Home />
            },
            {
                path: "home",
                element: <Home />
            },
            {
                path: "test-products",
                element: <TestProducts />
            },
            {
                path: "sale",
                element: <Sale />
            },
            {
                path: "bestseller",
                element: <Bestseller />
            },
            {
                path: "new-hits",
                element: <NewHits />
            },
            {
                path: "cart",
                element: <Cart />
            },
            {
                path: "collections",
                element: <CollectionLayout />,
                children: [
                    {   
                        index: true,
                        element: <Collections />
                    },
                    {
                        path: ":product",
                        element: <CollectionProducts />
                    }
                ]
            },
            {
                path: "search",
                element: <Search />
            },
            {
                path: "details/:product_name?",
                element: <Details />
            },
            {
                path: "payment-success",
                element: <PaymentSuccess />
            },
            {
                path: "payment-canceled",
                element: <PaymentCanceled />
            },
            {
                path: "profile",
                element: <Profile />
            },
            {
                path: "auth",
                children: [
                    {
                        index: true,
                        element: <Login />
                    },
                    {
                        path: "login",
                        element: <Login />
                    },
                    {
                        path: "register",
                        element: <Register />
                    },
                    {
                        path: "forgot-password",
                        element: <ForgotPassword />
                    },
                ]
            },
            {
                path: "*",
                element: <PageNotFound />
            }
        ]
    }
])

export default routes