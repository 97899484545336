import React, { useEffect, useState } from 'react'
import getCategories from '../../data/categories'

export default function categoriesStore() {

    const [categories, setCategories] = useState([])

    useEffect(()=>{
        getCategories(setCategories)
    }, [])

    return {
        categories,
        setCategories
    }
}
