import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { RouterProvider, useLocation } from 'react-router-dom'
import routes from "./routes"
import { Provider, useAuth } from './stores'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Modals from './modals'

function Main(){
  return (
    <Provider>
       <RouterProvider router={routes} />
       <ToastContainer position='bottom-right' closeButton={false} closeOnClick={false} pauseOnHover={false} />
       <Modals />
    </Provider>
  )
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <Main />
)
