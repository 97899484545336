import React, { useEffect, useState } from 'react'
import stringIsObject from "../../utils/string_is_object"
import { encrypt, decrypt } from '../../utils/products_crypto'

export default function cartStore() {
 
    const [cart, setCart] = useState(decrypt(localStorage.getItem("shopping_cart") || ""))

    const addToCart = (product_id, product_available)=>{

        if(product_available > 0){
            const findInCart = cart.find(p => p.id == product_id)
        
            if(!findInCart){
                setCart(prevCart => [...prevCart, { id: product_id, quantity: 1 }])
            }else {
                if(product_available > findInCart.quantity){
                    findInCart.quantity += 1;
                    setCart(prevCart => [...prevCart.filter(p => p.id !== product_id), findInCart])
                }else {
                    alert("Produkt Limit erreicht.")
                }
            }
        }
    
      }

      const decrementProduct = (product_id, count = false)=>{

        const findInCart = cart.find(p => p.id == product_id)

        if(!count){
            findInCart.quantity -= 1
        }else {
            findInCart.quantity = count
        }

        setCart(prevCart => [...prevCart.filter(p => p.id !== product_id), findInCart])

     }

      useEffect(()=>{   
        
        localStorage.setItem("shopping_cart", encrypt(cart))

      }, [cart])

    return {
        cart,
        setCart,
        addToCart,
        decrementProduct
    }

}
