import React from 'react'
import { IoClose } from "react-icons/io5"
import { useModal } from '../../../stores'

export default function ModalHeader({ title }) {

  const { closeModal } = useModal()
    
  return (
    <div className='w-full h-[60px] bg-white dark:bg-[#1c1c1c] flex items-center justify-between px-[15px] border-b border-[#ddd] dark:border-[dimgrey]'>
        <p className='text-[20px]'>{ title }</p>
        <div className='cursor-pointer' onClick={closeModal}>
            <IoClose size={25} />
        </div>
    </div>
  )
}
