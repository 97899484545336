import React, { useEffect, useState } from 'react'
import logoLight from "../../../../public/mysave-light.webp"
import logoDark from "../../../../public/mysave-dark.webp"
import { Link } from 'react-router-dom'
import { IoCart, IoCartOutline, IoEyedropOutline, IoMenu, IoMenuOutline, IoMoon, IoMoonOutline, IoPersonCircleOutline, IoSearchOutline, IoSunnyOutline } from "react-icons/io5"
import { useAuth, useCart, useDarkMode, useModal } from '../../../stores'
import SearchBrowser from './search-browser'
import SearchMobile from './search-mobile'
import MenuMobile from './menu-mobile'
import MenuBrowser from './menu-browser'
import useDebounce from '../../../hooks/use-debounce'
import getSearchResults from '../../../data/search-autocomplete'

export default function Header() {

    const [searchVisible, setSearchVisible] = useState(false)
    const [query, setQuery] = useState("")

    const queryValue = useDebounce(query, 500)

    const [searchResults, setSearchResults] = useState([]) 

    const [menuVisible, setMenuVisible] = useState(false)
    const { dark, setDark } = useDarkMode()

    const { createModal } = useModal()

    const { user } = useAuth()

    const { cart } = useCart()
    
    const quantityCount = cart.length > 0 && cart.reduce((a, b) => typeof b?.quantity === "number" ? a + b?.quantity : 0, 0) || 0;

    useEffect(()=>{
        if(queryValue.trim()){
            getSearchResults(queryValue, setSearchResults);
        }else {
            setSearchResults([])
        }
    }, [queryValue])

    return (
    <>
        <div className='w-full h-[80px] bg-white dark:bg-[#1c1c1c] dark:text-[lightgrey] dark:shadow-[0_0_7px_rgba(0,0,0,0.8)] shadow-[0_0_4px_rgba(0,0,0,0.2)] px-[8%] max-[1024px]:px-[15px] flex items-center justify-between fixed top-0 left-0 z-[10]'>
            
            <div className='flex items-center justify-center gap-x-[15px]'>
                <Link to={"/"} className='mt-[2px]'>
                    <img src={dark ? logoDark : logoLight} alt="logo" width={120} height={24} />
                </Link>
            </div>

            <SearchBrowser 
              query={query} 
              setQuery={setQuery} 
              setSearchVisible={setSearchVisible} 
              searchResults={searchResults}
            />

            <div className='h-full flex items-center justify-center gap-x-[15px] max-[550px]:gap-x-[12px]'>
                
                <div className='h-full flex items-center justify-center duration-300 hover:text-[var(--primary)] cursor-pointer max-[1024px]:hidden' onClick={()=>createModal("main.primary-switcher")}>
                    <IoEyedropOutline size={27} />
                </div>

                <div className='w-[28px] h-full flex items-center justify-center duration-300 hover:text-[var(--primary)] cursor-pointer max-[1024px]:hidden' onClick={()=>setDark(!dark)}>
                    { dark ? <IoSunnyOutline size={28} /> : <IoMoonOutline size={26} /> }
                </div>

                <div className='h-full flex items-center justify-center duration-300 hover:text-[var(--primary)] cursor-pointer min-[1024px]:hidden' onClick={()=>setSearchVisible(true)}>
                    <IoSearchOutline size={26}/>
                </div>

                { user ? (
                    <Link to={"/profile"} aria-label="profile" className='w-[35px] h-[35px] bg-[var(--primary)] text-white flex items-center justify-center text-center pt-[1px] font-[roboto-bold] rounded-full '>
                        { user.firstname.charAt(0) + user.lastname.charAt(0) }
                    </Link>
                ) : (
                    <Link to={"/auth/login"} aria-label="login" className='h-full flex items-center justify-center duration-300 hover:text-[var(--primary)]'>
                        <IoPersonCircleOutline size={30}/>
                    </Link>
                ) }

                <Link to={"/cart"} className='h-full flex items-center justify-center duration-300 hover:text-[var(--primary)] relative'>
                    <IoCartOutline size={32} />
                    <span className='min-w-[18px] h-[18px] bg-[var(--primary)] text-white text-[11px] flex items-center justify-center rounded-full px-[3px] absolute top-[20px] right-[-2px] border-[2px] border-white dark:border-[#1c1c1c] font-[roboto]'>{ quantityCount }</span>
                </Link>

                <div className='cursor-pointer h-full flex items-center justify-center duration-300 hover:text-[var(--primary)] min-[1024px]:hidden' onClick={()=>setMenuVisible(true)}>
                    <IoMenuOutline size={30} />
                </div>

            </div>
        </div>

        <SearchMobile 
          searchVisible={searchVisible} 
          setSearchVisible={setSearchVisible} 
          query={query}
          setQuery={setQuery}
          searchResults={searchResults}
        />

        <MenuMobile
          menuVisible={menuVisible} 
          setMenuVisible={setMenuVisible} 
        />

        <MenuBrowser />

    </>
  )
}
