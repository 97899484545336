import React, { useEffect, useState } from 'react'
import { post } from "../../../utils/http_fetch"
import { Link } from 'react-router-dom';
import { useCategories } from "../../../stores"

export default function Collections() {

  const { categories } = useCategories();

  return (
    <div className='py-[30px] px-[8%] max-[1024px]:px-[20px]'>

      <p className='text-center text-[30px] mb-[20px] font-[roboto-bold]'>UNSERE <span className='text-[var(--primary)] font-[roboto-bold]'>KOLLEKTIONEN</span></p>

      <div className='w-full grid grid-cols-4 max-[1400px]:grid-cols-2 max-[600px]:grid-cols-1 gap-6'>
        { categories.map((category, index)=>{
          return (
            <Link key={index} to={category._to} className='w-full h-[450px] relative group overflow-hidden shadow-[0_0_10px_rgba(0,0,0,0.2)] rounded-[15px]'>
              <img src={category.img} alt="collection" className='w-full h-full object-cover object-top absolute top-0 left-0 duration-500 group-hover:scale-105 z-[-1]' />
              <div className='w-full h-full bg-gradient-to-t from-[rgba(0,0,0,0.7)] from-[50%] to-transparent relative'>
                <div className='absolute bottom-[10px] left-0 text-white p-[20px]'>
                  <p className='text-[35px] font-[raleway-bold]'>{ category.title }</p>
                  <p>Entdecken Sie schöne Produkte</p>
                  <button className='px-[15px] h-[45px] cursor-pointer border border-white mt-[15px] rounded duration-300 group-hover:bg-white group-hover:text-black'>Jetzt Entdecken</button>
                </div>
              </div>
            </Link>
          )
        }) }
      </div>
    </div>
  )
}
