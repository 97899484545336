import React, { useEffect, useState } from 'react'
import { post } from '../../../utils/http_fetch'
import { useCart } from '../../../stores'
import { BACKEND_PHP_URL } from '../../../env'
import { encrypt as encryptProducts } from '../../../utils/products_crypto'
import { encrypt } from '../../../utils/crypto'
import md5 from 'md5'
import Product from '../../../components/elements/product'

export default function TestProducts() {

  const { cart, addToCart } = useCart()

  const [products, setProducts] = useState([])

  useEffect(()=>{
        showProducts();
  }, [])

  const showProducts = ()=>{
    post({
        api: "web/show-products",
        type: "json"
    })
    .then(data => {
        if(data?.success){
            setProducts(data.products)
        }else {
            toast.error(data?.msg)
        }
    })
  }
  
  return (
    <div className='w-full inline-block mt-[30px] pb-[20px]'>
        <div className='flex items-stretch justify-center flex-wrap px-[8%] gap-[20px]'>
            { products.map((product, index)=>{
                return (
                    <Product 
                     key={index}
                     product={product} 
                    />
                )
            }) }
        </div>
    </div>
  )
}
