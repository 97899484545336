import React from 'react'
import { useModal } from '../../../stores'

export default function ModalButtons({ text1 = "Abbrechen", text2 = "OK" }) {

  const { closeModal } = useModal()

  return (
    <div className='flex items-center justify-end max-[1024px]:justify-between gap-x-[12px]'>
        <button className='max-[1024px]:w-full min-[1024px]:px-[15px] h-[40px] rounded-full border-[1.5px] border-[#ddd] dark:border-[dimgrey] hover:shadow-[inset_0_0_0_20px_rgba(0,0,0,0.05)]' onClick={closeModal}>{ text1 }</button>
        <button className={`max-[1024px]:w-full ${ text2 === "OK" ? "min-[1024px]:px-[30px]" : "min-[1024px]:px-[15px]" } h-[40px] bg-[var(--primary)] duration-300 hover:shadow-[inset_0_0_0_20px_rgba(0,0,0,0.3)] text-white rounded-full`} onClick={closeModal}>{ text2 }</button>
    </div>
  )
}
