import React, { useEffect, useState } from 'react'
import { useCart, useModal, usePrimary } from "../../../stores"
import { post } from '../../../utils/http_fetch'
import { IoRemoveCircleOutline, IoAddCircleOutline, IoSearchOutline, IoCheckmarkCircle } from "react-icons/io5"
import Button from "../../../components/elements/primary-button"
import { Link, useNavigate } from 'react-router-dom'
import { Bars, MutatingDots } from 'react-loader-spinner'
import useDebounce from '../../../hooks/use-debounce'
import { toast } from 'react-toastify'
import { BACKEND_PHP_URL } from '../../../env'
import { encrypt as encryptProducts } from '../../../utils/products_crypto'
import { encrypt } from '../../../utils/crypto'
import md5 from 'md5'

export default function Cart() {

  const navigate = useNavigate()

  const { primary } = usePrimary()

  const { cart, setCart, decrementProduct, addToCart } = useCart()

  const [cartProducts, setCartProducts] = useState([])

  const [couponVisible, setCouponVisible] = useState(false)
  const [code, setCode] = useState("")
  const [successCode, setSuccessCode] = useState(false)
  const [searched, setSearched] = useState(false)

  const queryCode = useDebounce(code, 500)

  let total = 0
  let totalQuantity = 0
  let totalShipment = 0

  useEffect(()=>{
    showCart()
  }, [])

  const showCart = async ()=>{
    await post({
      api: "web/show-cart",
      values: {
        product_ids: JSON.stringify(cart.reduce((a, b) => [...a, b?.id], [])) 
      }
    })
    .then(data => {
      if(data?.success){
        setCartProducts(data.products)
      }else {
        toast.error(data?.msg)
      }
    })
  }

  const removeFromCart = (product_id)=>{
    setCart(prevCart => prevCart.filter(p => p.id !== product_id))
    setCartProducts(prevCartProducts => prevCartProducts.filter(p => p.id !== product_id))
  }

  useEffect(()=>{

    if(queryCode.trim()){
      searchCode()
    }else {
      setSuccessCode(false)
    }

  }, [queryCode])

  useEffect(()=>{
    setSearched(false)
  }, [code])

  const searchCode = ()=>{
    post({
      api: "web/coupon-code",
      values: {
        code: queryCode.trim() 
      }
    })
    .then(data => {
      if(data?.success){
        setSuccessCode(data.coupon)
      }else {
        setSuccessCode(false)
      }
    })
    .finally(()=>{
      setSearched(true) 
    })
  }

  useEffect(()=>{ 
    if(cartProducts.length > 0){
      checkProductCounts()      
    }
  }, [cartProducts])

  const checkProductCounts = ()=>{
    
    cart.map((cartProduct)=>{

      const findProduct = cartProducts.find(p => p.id === cartProduct.id)

      if(findProduct.count === 0 && cartProduct.quantity > findProduct.count){
        removeFromCart(cartProduct.id)
      }else if(findProduct.count > 0 && cartProduct.quantity > findProduct.count) {
        decrementProduct(cartProduct.id, findProduct.count)
      }
      
    })

  }

  const showProductDetails = (e, name)=>{
    if(e.target.name !== "decrement" && e.target.name !== "increment" && e.target.name !== "delete"){
      navigate(`/details/${ name }`)
    }
  }

  return (
    <div className='w-full  inline-block mt-[25px] pb-[30px]'>
      <div className='w-full px-[8%] flex max-[1024px]:px-[20px] max-[1024px]:flex-col-reverse items-start justify-center mx-auto gap-[30px] rounded-[10px]'>
        <div className='w-full shadow-[0_0_5px_rgba(0,0,0,0.2)] dark:bg-[#333333]'>
          <div className='w-full h-[60px] flex items-center justify-between px-[15px] border-b border-[#ddd] dark:border-[dimgrey]'>
            <p className='text-[20px]'>Einkaufswagen</p>
            { cartProducts.length > 0 && (
               <div className='underline text-[var(--primary)] cursor-pointer' onClick={()=>{setCart([]); setCartProducts([])}}>Warenkorb Leeren</div>
            ) }
          </div>
          <div className='px-[20px]'>
            { cartProducts.length > 0 ? cartProducts.map((product, index)=>{

              const findQuantity = cart.find(p => p?.id == product.id)?.quantity || 0

              total += parseFloat((findQuantity || 0) * (product.sale_percent === "NO_SALE" ? product.price : (product.price - (product.price * `0.${parseFloat(product.sale_percent) <= 9 ? "0" + product.sale_percent : product.sale_percent}`))))
              totalQuantity += findQuantity
              totalShipment += product.shipment;

              return (
                <div key={index} className='w-full min-h-[250px] py-[20px] max-[550px]:h-auto max-[550px]:flex-col flex items-start justify-start border-b border-[#ddd] dark:border-[dimgrey] last:border-none gap-x-[35px] gap-y-[20px] relative cursor-pointer'  onClick={e => showProductDetails(e, product.url)}>
                  <img src={"https://assets.adidas.com/images/h_840,f_auto,q_auto,fl_lossy,c_fill,g_auto/bf9cb1cf4d5541e984e9aefa00b6e59d_9366/Tiro_23_League_Hose_Schwarz_HS7232_21_model.jpg"} width={150} height={"100%"} className='w-[200px] max-[550px]:w-full h-full object-cover' alt='product' />
                  <div className='max-[550px]:w-full'>
                    <p className='text-[21px] hover:underline'>{ product.title }</p>
                    { product.sale_percent === "NO_SALE" ? (
                        <p>{ product.price.toLocaleString("de-DE", { style: "currency", currency: "EUR" }) }</p>
                    ) : (
                        <>
                            <p className='flex items-center justify-start gap-x-[10px]'>
                                <span className='line-through'>{ product.price.toLocaleString("de-DE", { style: "currency", currency: "EUR" }) }</span>
                                <span className='text-[crimson]'>{ (product.price - (product.price * `0.${ parseFloat(product.sale_percent) <= 9 ? "0" + product.sale_percent : product.sale_percent }`)).toLocaleString("de-DE", { style: "currency", currency: "EUR" }) }</span>
                            </p>
                            <p>{ product.sale_percent }% Rabatt</p>
                        </>
                    ) }
                    <span className={`${product.count === 0 ? 'text-[crimson]' : 'text-[#4bb71b]'} text-[15px]`}>({ product.count === 0 ? "Nicht vorrätig" : "Auf Lager" })</span>
                    <p className='mt-[15px]'>Mengen Preis: { (parseFloat((findQuantity || 0) * (product.sale_percent === "NO_SALE" ? product.price : (product.price - (product.price * `0.${parseFloat(product.sale_percent) <= 9 ? "0" + product.sale_percent : product.sale_percent}`))))).toLocaleString("de-DE", { style: "currency", currency: "EUR" }) }</p>
                    <div className='flex items-center justify-start mt-[15px] gap-x-[15px]'>
                      <div className='h-[40px] flex items-center justify-center border border-[#ddd] dark:border-[dimgrey] rounded overflow-hidden'>
                        <button name='decrement' disabled={findQuantity === 1} className='w-[40px] h-full bg-[whitesmoke] dark:bg-[rgba(0,0,0,0.2)] flex items-center justify-center border-[#ddd] dark:border-[dimgrey] cursor-pointer hover:bg-[var(--primary)] hover:text-white duration-300 disabled:opacity-30 disabled:cursor-not-allowed' onClick={()=>decrementProduct(product.id)}>
                          <IoRemoveCircleOutline size={22} className='pointer-events-none' />
                        </button>
                        <div className='w-[40px] h-full flex items-center justify-center border-l border-r border-[#ddd] dark:border-[dimgrey]'>{ findQuantity }</div>
                        <button name='increment' disabled={findQuantity === product.count} className='w-[40px] h-full bg-[whitesmoke] dark:bg-[rgba(0,0,0,0.2)] flex items-center justify-center cursor-pointer hover:bg-[var(--primary)] hover:text-white duration-300 disabled:opacity-30 disabled:cursor-not-allowed' onClick={()=>addToCart(product.id, product.count)}>
                          <IoAddCircleOutline size={22} className='pointer-events-none' />
                        </button>
                      </div>
                     <button name='delete' className='text-[var(--primary)] underline' onClick={()=>removeFromCart(product.id)}>Löschen</button>
                    </div>
                  </div>
                </div>
              )
            }) : (
              <div className='w-full h-[250px] flex flex-col items-center justify-center'>
                <p className='text-[23px]'>Dein Warenkorb ist leer</p>
                <p className='text-[15px] mt-[5px]'>Möchten Sie sich Anmelden</p>
                <div className='w-[400px] max-[550px]:w-full h-[45px] flex items-center justify-between gap-x-[8px] mt-[18px]'>
                  <Link to={"/auth/login"} className='w-full h-full bg-[var(--primary)] rounded-full flex items-center justify-center text-white'>Login</Link>
                  <Link to={"/auth/register"} className='w-full h-full border border-[var(--primary)] text-[var(--primary)] rounded-full flex items-center justify-center'>Registrieren</Link>
                </div>
              </div>
            ) }
          </div>
        </div>
        <div className='w-[500px] h-[500px] max-[1024px]:w-full shadow-[0_0_5px_rgba(0,0,0,0.2)] dark:bg-[#333333] relative'>
          <div className='w-full h-[60px] flex items-center justify-start px-[15px] border-b border-b-[#ddd] dark:border-b-[dimgrey] text-[20px]'>Jetzt Kaufen</div>
          <div className='p-[15px]'>
            <button className='w-full h-[45px] border-[var(--primary)] border text-[var(--primary)] rounded mb-[15px]' onClick={()=>setCouponVisible(!couponVisible)}>{ couponVisible ? "Gutschein Nicht einsätzen" : "MYSAVE-Gutschein Einlösen" }</button>
            <div className={`${ couponVisible ? 'h-[50px]' : 'h-0' } duration-300 overflow-hidden flex items-center justify-between rounded mb-[10px] relative`}>
              <input type="text" placeholder='Gutschein-Code' className='w-full h-full bg-transparent border border-[#ddd] dark:border-[dimgrey] focus:border-[var(--primary)] duration-300 outline-none pl-[15px] rounded' value={code} onChange={e => setCode(e.target.value)} />
              
              { code.trim() && !searched && (
                <Bars
                height="80"
                width="80"
                color={primary}
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="scale-[0.3] absolute right-[-10px]"
                visible={true}
                />
              ) }

              { code.trim() && successCode && (
                <svg className="checkmark w-[25px] h-[25px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
              ) }

            </div>
            <div className='pb-[10px] border-b border-[#ddd] dark:border-[dimgrey] leading-[1.7]'>
              <p className='text-[15px]'>Artikel: { totalQuantity }</p>
              <p className='text-[15px]'>Zwischensumme: { total.toLocaleString("de-DE", { style: "currency", currency: "EUR" }) }</p>
              <p className='text-[15px]'>Mwst. (19%): { ((total) * (cartProducts.length > 0 ? 0.19 : 0)).toLocaleString("de-DE", { style: "currency", currency: "EUR" }) }</p>
              <p className='text-[15px]'>Versand: { totalShipment.toLocaleString("de-DE", { style: "currency", currency: "EUR" }) }</p>
              <p className='text-[15px]'>Gutschein: -{ (successCode ? successCode.price : 0).toLocaleString("de-DE", { style: "currency", currency: "EUR" }) }</p>
            </div>
            <p className='text-[18px] mt-[15px]'>Gesamt: { (((total) * (cartProducts.length > 0 ? 0.19 : 0)) + total + totalShipment - (successCode ? successCode.price : 0)).toLocaleString("de-DE", { style: "currency", currency: "EUR" }) }</p>
            <p className='text-[13px]'>Inklusiv Mwst. (19%)</p>
          </div>
          <form action={`${ BACKEND_PHP_URL }web/checkout`} method='post' className='w-full absolute bottom-[15px] px-[15px]'>
            <input type='hidden' name='action' value={'true'} />
            <input type='hidden' name={"_" + md5('products')} value={encryptProducts(cart)} />
            <input type='hidden' name={"_" + md5('total_price')} value={encrypt((((total) * (cartProducts.length > 0 ? 0.19 : 0)) + total + totalShipment - (successCode ? successCode.price : 0)).toString())} />
            <input type='hidden' name={"clear_cart"} value={'true'} />
            { code.trim() && successCode && (
            <input type='hidden' name={"_" + md5('coupon_code')} value={encrypt(successCode.price.toString())} />
            ) }
            <Button disabled={cartProducts.length === 0} type="submit" className={'rounded mt-[50px]'}>Zur Kasse</Button>
          </form>
        </div>
      </div>
     </div>
  )
}
