const footerNavigations = [
    {
        to: "/contact",
        title: "Kontakt"
    },
    {
        to: "/about",
        title: "Über uns"
    },
    {
        to: "/cookie-settings",
        title: "Cookie-Einstellungen"
    },
    {
        to: "/terms-conditions",
        title: "AGB's"
    },
    {
        to: "/privacy",
        title: "Datenschutz"
    },
    {
        to: "/imprint",
        title: "Impressum"
    }
]

export default footerNavigations