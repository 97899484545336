import React, { useEffect, useState } from 'react'
import { IoClose } from 'react-icons/io5'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { post } from "../../../utils/http_fetch"

export default function ProductFilter({ productFilterVisible, setProductFilterVisible, filterStats, inStock, setInStock, notInStock, setNotInStock }) {

  return (
    <>
        <div className={`w-full h-full bg-[rgba(0,0,0,0.7)] fixed top-0 left-0 z-[14] duration-500 ${ productFilterVisible ? 'visible' : 'invisible opacity-0' }`} onClick={()=>setProductFilterVisible(false)}></div>
        <div className={`w-[350px] max-[550px]:w-full h-full bg-white dark:bg-[#1c1c1c] fixed top-0 duration-300 ${ productFilterVisible ? 'right-0' : 'right-[-350px] max-[550px]:right-[-100%]' } z-[15] overflow-y-auto pb-[150px]`}>
            <div className='w-full h-[80px] bg-white dark:bg-[#1c1c1c] shadow-[0_0_7px_rgba(0,0,0,0.2)] dark:shadow-[0_0_7px_rgba(0,0,0,0.8)] flex items-center justify-between px-[15px] sticky top-0 z-[5]'>
                <p className='text-[20px]'>Produkt Filtern</p>
                <div className='w-[40px] h-[40px] rounded-full bg-[whitesmoke] dark:bg-[#333333] flex items-center justify-center cursor-pointer duration-500 hover:bg-[var(--primary)] hover:text-white' onClick={()=>setProductFilterVisible(false)}>
                    <IoClose size={20} />
                </div>
            </div>
            <div className='p-[15px]'>
              <div className='w-full h-[100px] bg-[whitesmoke] dark:bg-[#333333] rounded px-[15px]'>
                <label htmlFor='in-stock' className={`w-full flex h-[50px] items-center justify-start gap-x-[10px] cursor-pointer border-b border-[#ddd] dark:border-[dimgrey] ${ filterStats.in_stock === 0 ? 'opacity-50 cursor-not-allowed' : '' }`}>
                  <input disabled={filterStats.in_stock === 0} type="checkbox" id='in-stock' checked={inStock} onClick={e => { setInStock(e.target.checked); setProductFilterVisible(false) }} />
                  <p>Auf Lager ({ filterStats.in_stock })</p>
                </label>
                <label htmlFor='not-in-stock' className={`w-full flex h-[50px] items-center justify-start gap-x-[10px] cursor-pointer ${ filterStats.not_in_stock === 0 ? 'opacity-50 cursor-not-allowed' : '' }`}>
                  <input disabled={filterStats.not_in_stock === 0} type="checkbox" id='not-in-stock' checked={notInStock} onClick={e => { setNotInStock(e.target.checked); setProductFilterVisible(false) }} />
                  <p>Nicht vorrätig ({ filterStats.not_in_stock })</p>
                </label>
              </div>
            </div>
        </div>
    </>
  )
}
