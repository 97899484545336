import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import validation from '../../../validations/auth/login-validation'
import Button from '../../../components/elements/primary-button'
import { post } from "../../../utils/http_fetch"
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import logoLight from "../../../../public/mysave-light.webp"
import logoDark from "../../../../public/mysave-dark.webp"
import { useAuth, useDarkMode } from '../../../stores'

export default function Login() {

  const { dark } = useDarkMode()
  const { setUser } = useAuth();

  const navigate = useNavigate()

  const login = values => {
    post({
      api: "web/auth/login",
      values,
      type: "json"
    })
    .then(data => {
      if(data?.success){
        
        toast.success("Login erfolgreich")
        navigate("/")

        localStorage.setItem("token", data.token)

        setUser({
          ...data.user,
          token: data.token
        })

      }else {
        toast.error(data?.msg)
      }
    })
  }

  return (
    <div className='w-full max-[1024px]:h-auto flex items-start justify-center px-[20px]'>
      <div className='w-[350px] max-[1024px]:w-full mt-[30px]'>
        <img src={dark ? logoDark : logoLight} alt="logo" className='w-[150px] mx-auto mb-[20px]' />
        <p className='text-[28px] text-center font-[raleway] font-bold mt-[30px]'>Einloggen</p>
        <Formik
          validationSchema={validation}
          initialValues={{
            email: "",
            password: ""
          }}
          onSubmit={login}
        >
          {({ dirty, isValid }) => (
              <Form>

              <Field type="text" placeholder='E-Mail-Adresse *' className='input' name='email' />
              <ErrorMessage component={"div"} className='text-black dark:text-white mt-[15px]' name='email' />

              <Field type="password" placeholder='Passwort *' className='input' name='password' />
              <ErrorMessage component={"div"} className='text-black dark:text-white mt-[15px]' name='password' />

              <Button disabled={!dirty || !isValid} type="submit" className='mt-[20px] rounded'>Login</Button>

              <Link to={"/auth/register"} className='w-full h-[50px] bg-[whitesmoke] dark:bg-[rgba(0,0,0,0.3)] flex items-center justify-center mt-[15px] rounded'>
                Registrieren
              </Link>
              
              <Link to={"/auth/forgot-password"} className='underline mt-[20px] inline-flex'>Passwort vergessen ?</Link>

            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
