import React, { useEffect, useState } from 'react'
import { IoClose, IoSearchOutline } from 'react-icons/io5'
import useSearch from '../../../../hooks/use-search'
import { Link, useNavigate } from 'react-router-dom'

export default function SearchBrowser({ query, setQuery, setSearchVisible, searchResults }) {

  const navigate = useNavigate()
  const search = useSearch(query, setQuery, setSearchVisible)

  const [focusedIndex, setFocusedIndex] = useState(-1);

  const nextSearchElementFocus = e => {
    if (query.trim() && searchResults.length > 0) {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setFocusedIndex((prevIndex) => Math.min(prevIndex + 1, searchResults.length - 1));
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        setFocusedIndex((prevIndex) => Math.max(prevIndex - 1, -1));
      } else if (e.key === 'Enter' && focusedIndex !== -1) {
        e.preventDefault();
        navigate(`/details/${searchResults[focusedIndex].url}`);
        setQuery("")
      }
    }
  }

  useEffect(()=>{
    setFocusedIndex(-1)
  }, [query])

  return (
    <>

      <div className='w-[650px] max-[1200px]:w-[500px] h-[45px] max-[1024px]:hidden relative flex items-center justify-center group'>
        <form className='w-full h-full dark:bg-[rgba(0,0,0,0.4)] rounded-[5px] overflow-hidden flex items-center justify-between shadow-[0_0_8px_lightgrey] dark:shadow-[0_0_8px_rgba(0,0,0,0.2)]' autoComplete='off' onSubmit={search}>
            <input type="text" name='search-browser' className='w-full h-full bg-transparent outline-none pl-[15px] placeholder:text-[dimgrey]' value={query} placeholder='Suche Halsketten, Armbänder & Co.' autoComplete='off' onKeyDown={nextSearchElementFocus} onChange={e => setQuery(e.target.value)} />
            <div className='h-full flex items-center justify-center'>
              { query && (
                  <div className='px-[15px] cursor-pointer' onClick={()=>setQuery("")}>
                      <IoClose size={23} />
                  </div>
                ) }
              <button type='submit' className='px-[15px] rounded-l-[5px] gap-x-[8px] h-full bg-[var(--primary)] flex items-center justify-center text-white' aria-label="search">
                  <IoSearchOutline size={25} />
                  <p className='mt-[2px]'>Suchen</p>
              </button>
            </div>
        </form>

        { query.trim() && (
          <div className='w-full py-[15px] absolute bg-white dark:bg-[#333333] shadow-[0_0_8px_lightgrey] dark:shadow-[0_0_8px_rgba(0,0,0,0.2)] rounded top-[70px] hidden group-focus-within:block group-focus-within:top-[55px] opaAnimate'>
              <div className={`px-[15px] ${ searchResults.length > 0 ? 'mb-[10px]' : '' }`}>Suche: "{ query }"</div>
              { searchResults.length > 0 && (
                <div className='mt-[10px] mb-[15px] px-[15px] text-[20px]'>Produkte</div>
              ) }
              { searchResults.map((result, index)=>{
                return (
                  <Link key={index} to={`/details/${ result.url }`}  className={`w-full h-[90px] border-b border-[#ddd] dark:border-[dimgrey] last:border-b-0 flex items-center justify-start gap-x-[15px] hover:bg-[rgba(0,0,0,0.03)] px-[15px] ${ focusedIndex === index ? 'bg-[rgba(0,0,0,0.06)]' : '' }`} onClick={()=>setQuery("")}>
                    <img src={"https://assets.adidas.com/images/h_840,f_auto,q_auto,fl_lossy,c_fill,g_auto/bf9cb1cf4d5541e984e9aefa00b6e59d_9366/Tiro_23_League_Hose_Schwarz_HS7232_21_model.jpg"} alt="resultImg" width={60} height={60} className='w-[60px] h-[60px] object-contain' loading='lazy' />
                    <div>
                      <p className='text-[18px]'>{ result.title }</p>
                      { result.sale_percent === "NO_SALE" ? (
                          <p className='text-[15px]'>{ result.price.toLocaleString("de-DE", { style: "currency", currency: "EUR" }) }</p>
                      ) : (
                          <>
                              <p className='flex items-center justify-start gap-x-[10px] text-[15px]'>
                                  <span className='line-through'>{ result.price.toLocaleString("de-DE", { style: "currency", currency: "EUR" }) }</span>
                                  <span className='text-[crimson]'>{ (result.price - (result.price * `0.${ parseFloat(result.sale_percent) <= 9 ? "0" + result.sale_percent : result.sale_percent }`)).toLocaleString("de-DE", { style: "currency", currency: "EUR" }) }</span>
                              </p>
                              <p className='text-[13px]'>{ result.sale_percent }% Rabatt</p>
                          </>
                      ) }
                    </div>
                  </Link>
                )
              }) }
          </div>
        ) }

      </div>
    </>
  )
}
