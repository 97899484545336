const stringIsObject = (value)=>{
 
    const newValue = value || []
    
    try {
        return JSON.parse(newValue)
    } catch(e) {
        return []
    }
}

export default stringIsObject