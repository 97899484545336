import React from 'react'
import { useCart } from '../../../stores'
import { BACKEND_PHP_URL } from '../../../env'
import md5 from 'md5'
import { encrypt as encryptProduct } from '../../../utils/products_crypto'
import { encrypt } from '../../../utils/crypto'
import { Link, useNavigate } from 'react-router-dom'

export default function Product({ product }) {

  const navigate = useNavigate()
  const { addToCart } = useCart()

  const showProductDetails = (e, name)=>{
    if(e.target.name !== "order-product" && e.target.name !== "checkout" && e.target.name !== "add-to-cart"){
        navigate(`/details/${ name }`)
    }
  }

  return (
    <div className='w-full h-full dark:bg-[#333333] shadow-[0_0_4px_rgba(0,0,0,0.2)] cursor-pointer' onClick={e => showProductDetails(e, product.url)}>
            <div className='w-full relative'>
                <img src={"https://assets.adidas.com/images/h_840,f_auto,q_auto,fl_lossy,c_fill,g_auto/bf9cb1cf4d5541e984e9aefa00b6e59d_9366/Tiro_23_League_Hose_Schwarz_HS7232_21_model.jpg"} width={"100%"} height={300} className='w-full object-cover' alt="productImg" />
                { product.sale_percent !== "NO_SALE" && (
                    <div className='absolute bottom-[15px] py-[3px] bg-white/90 text-[crimson] pl-[15px] pr-[10px] rounded-r-[5px]'>{ product.sale_percent }% Rabatt</div>
                ) }
            </div>
            <div className='w-full p-[15px]'>
                <p className='text-[20px]'>{ product.title }</p>
                { product.sale_percent === "NO_SALE" ? (
                    <p>{ product.price.toLocaleString("de-DE", { style: "currency", currency: "EUR" }) }</p>
                ) : (
                    <>
                        <p className='flex items-center justify-start gap-x-[10px]'>
                            <span className='line-through'>{ product.price.toLocaleString("de-DE", { style: "currency", currency: "EUR" }) }</span>
                            <span className='text-[crimson]'>{ (product.price - (product.price * `0.${ parseFloat(product.sale_percent) <= 9 ? "0" + product.sale_percent : product.sale_percent }`)).toLocaleString("de-DE", { style: "currency", currency: "EUR" }) }</span>
                        </p>
                    </>
                ) }
                <span className={`${product.count === 0 ? 'text-[crimson]' : 'text-[#4bb71b]'} text-[15px] mt-[2px]`}>({ product.count === 0 ? "Nicht vorrätig" : "Auf Lager" })</span>  
                
                <p className='mt-[10px] text-[15px]'>Lagerbestand: { product.count }</p>

                { product.count > 0 ? (
                    <form action={`${ BACKEND_PHP_URL }web/checkout`} method='post' className='w-full h-[45px] mt-[15px]'>
                        <input type='hidden' name='action' value={'true'} className='hidden' />
                        <input type="hidden" name={"_" + md5('products')} value={encryptProduct([{ id: product.id, quantity: 1 }])} className='hidden' />
                        <input type="hidden" name={"_" + md5('total_price')} value={encrypt((product.price + product.shipment).toString())} className='hidden' />
                        <button type='submit' name='checkout' className='w-full h-full border-[var(--primary)] border text-[var(--primary)] rounded'>Kaufen</button>
                    </form>
                ) : (
                    <Link to={`/order-product/${ product.url }`} name='order-product' className='w-full h-[45px] border-[var(--primary)] border text-[var(--primary)] rounded flex items-center justify-center mt-[15px]'>Bestellen</Link>
                )  }

                { product.count > 0 && (
                    <button name='add-to-cart' className='w-full h-[45px] bg-[var(--primary)] rounded text-white mt-[15px]' onClick={()=>addToCart(product.id, product.count)}>Warenkorb</button>
                ) }

            </div>
        </div>
  )
}
