import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { post } from "../../../utils/http_fetch"
import { BACKEND_PHP_URL } from '../../../env';
import { useCart } from '../../../stores';
import { encrypt as encryptProduct } from '../../../utils/products_crypto';
import { encrypt } from '../../../utils/crypto';
import md5 from 'md5';
import { IoAlertCircleOutline } from 'react-icons/io5' 

export default function Details() {

  const { product_name } = useParams();

  const { addToCart } = useCart();

  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(false);

  useEffect(()=>{
      showProduct();
  }, [product_name])

  const showProduct = ()=>{
    post({
      api: "web/show-product",
      values: {
        product_name
      }
    })
    .then(data => {
      if(data?.success){
        setProduct(data.product)
      }
    })
    .finally(()=>{
      setLoading(false)
    })
  }

  return (
    <>
      { (!loading && product) && (
      <div className='w-full inline-block mt-[30px] p-[20px] pt-0'>
          <div className='w-full max-w-[1100px] mx-auto'>
            
            <div className='w-full flex max-[1024px]:flex-col items-start max-[1024px]:items-center justify-between gap-6'>
              <div className='w-full'>
                <div className='w-full h-[550px] max-[550px]:h-[300px] bg-[whitesmoke] relative'>
                  <img src={"https://assets.adidas.com/images/h_840,f_auto,q_auto,fl_lossy,c_fill,g_auto/bf9cb1cf4d5541e984e9aefa00b6e59d_9366/Tiro_23_League_Hose_Schwarz_HS7232_21_model.jpg"} alt="productImg" width={"100%"} height={580} className='w-full h-full object-cover' />
                  { product.sale_percent !== "NO_SALE" && (
                      <div className='absolute bottom-[15px] py-[5px] bg-white/90 text-[crimson] pl-[15px] pr-[10px] rounded-r-[5px] text-[18px]'>{ product.sale_percent }% Rabatt</div>
                  ) }
                </div>
              </div>
              <div className='max-w-[400px] min-w-[400px] max-[1024px]:min-w-full max-[1024px]:max-w-full h-[550px] flex flex-col items-center justify-between p-[15px] shadow-[0_0_4px_rgba(0,0,0,0.2)] dark:bg-[#333333]'>
                <div>
                <p className='text-[23px] font-[roboto-bold]'>{ product.title }</p>
                { product.sale_percent === "NO_SALE" ? (
                      <p className='text-[25px]'>{ product.price.toLocaleString("de-DE", { style: "currency", currency: "EUR" }) }</p>
                  ) : (
                      <>
                          <p className='flex items-center justify-start gap-x-[10px] text-[23px]'>
                              <span className='line-through'>{ product.price.toLocaleString("de-DE", { style: "currency", currency: "EUR" }) }</span>
                              <span className='text-[crimson]'>{ (product.price - (product.price * `0.${ parseFloat(product.sale_percent) <= 9 ? "0" + product.sale_percent : product.sale_percent }`)).toLocaleString("de-DE", { style: "currency", currency: "EUR" }) }</span>
                          </p>
                      </>
                  ) }
                  <span className={`${product.count === 0 ? 'text-[crimson]' : 'text-[#4bb71b]'} text-[18px] mt-[10px]`}>({ product.count === 0 ? "Nicht vorrätig" : "Auf Lager" })</span>  
                  <p className='mt-[15px]'>Produkt-ID: #{ product.id }</p>
                  <p>Versand: { product.shipment.toLocaleString("de-DE", { style: "currency", currency: "EUR" }) }</p>
                  <p>Lagerbestand: { product.count }</p>

                  <p className='text-[15px] mt-[10px]'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>

                  </div>

                  <div className='w-full'>

                  { product.count > 0 ? (
                      <form action={`${ BACKEND_PHP_URL }web/checkout`} method='post' className='w-full h-[45px] mt-[15px]'>
                          <input type='hidden' name='action' value={'true'} className='hidden' />
                          <input type="hidden" name={"_" + md5('products')} value={encryptProduct([{ id: product.id, quantity: 1 }])} className='hidden' />
                          <input type="hidden" name={"_" + md5('total_price')} value={encrypt((product.price + product.shipment).toString())} className='hidden' />
                          <button type='submit' name='checkout' className='w-full h-full border-[var(--primary)] border text-[var(--primary)] rounded'>Kaufen</button>
                      </form>
                  ) : (
                      <Link to={`/order-product/${ product.url }`} name='order-product' className='w-full h-[45px] border-[var(--primary)] border text-[var(--primary)] rounded flex items-center justify-center mt-[15px]'>Bestellen</Link>
                  )  }

                  { product.count > 0 && (
                      <button name='add-to-cart' className='w-full h-[45px] bg-[var(--primary)] rounded text-white mt-[15px]' onClick={()=>addToCart(product.id, product.count)}>Warenkorb</button>
                  ) }

                </div>

              </div>
            </div>

            <p className='font-[roboto-bold] text-[25px] mt-[30px]'>Beschreibung</p>
            <p className='text-[15px] mt-[5px] leading-[2]'>{ product.description }</p>

          </div>
          </div>
      ) }

      { (!loading && !product) && (
        
          <div className='w-full px-[20px] min-h-[calc(100vh-90px)] max-w-[1200px] mx-auto flex flex-col text-center items-center justify-center'>
            <IoAlertCircleOutline size={80} />
            <p className='mt-[10px] text-[20px]'>Leider haben wir ihr gesuchtes Produkt nicht gefunden.</p>
          </div>

      ) }

      </>
  )
}
