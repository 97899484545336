import React from 'react'
import { Link } from 'react-router-dom'
import { useCategories } from '../../../../stores'
import { IoChevronDown } from 'react-icons/io5'

export default function MenuBrowser() {

  const { categories } = useCategories()

  return (
    <>
      <div className='w-full h-[70px] bg-white dark:bg-[#1c1c1c] max-[1024px]:hidden flex items-center justify-start px-[8%] gap-x-[15px] border-b border-[#ddd] dark:border-[dimgrey] fixed top-[80px] z-[9]'>
          { categories.map((category, index)=>{
              return (
                <div key={index} className='h-full relative group'>
                  <Link to={category._to} className='h-full flex items-center justify-center gap-x-[5px] relative group-hover:text-[var(--primary)] duration-300 group px-[5px]'>
                      <p className='text-[14px]'>{ category.title }</p>
                      { category.sub.length > 0 && (
                       <IoChevronDown />
                      ) }
                      <div className={`w-0 h-[2px] absolute bottom-0 duration-300 bg-[var(--primary)] group-hover:w-full`}></div>
                  </Link>
                  { category.sub.length > 0 && (
                    <div className='w-[200px] bg-white dark:bg-[#333333] shadow-[0_0_8px_rgba(0,0,0,0.2)] mt-[2px] duration-300 absolute left-0 invisible opacity-0 group-hover:visible group-hover:opacity-100 py-[20px]'>
                      <Link to={category._to} className='w-full h-[40px] text-[15px] flex items-center justify-start duration-300 border-l-[2px] border-transparent hover:text-[var(--primary)] hover:border-[var(--primary)] pl-[12px]'>Alle { category.title }</Link>
                      { category.sub.map((sub_category, sub_index)=>{
                        return (
                          <Link key={sub_index} to={sub_category._to} className='w-full h-[40px] text-[15px] flex items-center justify-start duration-300 border-l-[2px] border-transparent hover:text-[var(--primary)] hover:border-[var(--primary)] pl-[12px] mt-[15px]'>{ sub_category.title }</Link>
                        )
                      }) }
                    </div>
                  ) }
                </div>
              )
          }) }
      </div>

    </>
  )
}
