import React from 'react'
import vipsdesign from "../../../../public/vipsdesign.webp"
import { IoCall, IoLocation, IoLogoWhatsapp, IoMail } from 'react-icons/io5'
import footerNavigationsData from '../../../consts/footer-navigations'
import { Link } from 'react-router-dom'
import { IoLogoFacebook, IoLogoInstagram, IoLogoLinkedin, IoLogoTwitter, IoLogoYoutube } from "react-icons/io5"
import { FaXTwitter } from "react-icons/fa6";

export default function Footer() {

   const social_media = [
        {
            href: "https://www.twitter.com",
            icon: <FaXTwitter size={25} color="#1DA1F2" />,
            title: "X"
        },    
        {
            href: "https://www.youtube.com",
            icon: <IoLogoYoutube size={25} color="#FF0000" />,
            title: "Youtube"
        },
        {
            href: "https://www.linkedin.com",
            icon: <IoLogoLinkedin size={25} color="#0A66C2" />,
            title: "Linkedin"
        },
        {
            href: "https://www.facebook.com",
            icon: <IoLogoFacebook size={25} color="#4267B2" />,
            title: "Facebook"
        },
        {
            href: "https://www.instagram.com",
            icon: <IoLogoInstagram size={25} color="#E1306C" />,
            title: "Instagram"
        },
        {
            href: "https://www.whatsapp.com",
            icon: <IoLogoWhatsapp size={25} color="#25D366" />,
            title: "Whatsapp"
        }
    ]

  return (
    <>

    <div className='w-full bg-white dark:bg-[#212121] py-[30px] px-[8%] max-[1024px]:px-[20px] border-t border-[#ddd] dark:border-[dimgrey]'>
        {/* <div className='w-full h-[50px] border-b border-[dimgrey] flex items-center justify-start gap-x-[15px]'>
            <img src={logo} alt="logo" width={35} height={35} />
            <p className='text-[17px] text-white'>Taxihelp.de</p>
        </div> */}
        <div className='flex max-[1024px]:flex-col items-center justify-between gap-x-[20px] gap-y-[50px] mt-[25px]'>
            <div className='w-full max-[1024px]:h-auto h-[550px] min-[1024px]:border-r border-[#ddd] dark:border-[dimgrey] min-[1024px]:pr-[20px]'>
            
                <p className='text-center font-[roboto-bold] mb-[10px] mt-[15px] text-[25px]'>Firmen Adresse</p>

                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2551572.7984937876!2d3.542445176357564!3d51.35213411461615!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b8c17f8720c26d%3A0xac8854a218ac8bf6!2sR%C3%BChlweg%2034%2C%2045470%20M%C3%BClheim%20an%20der%20Ruhr!5e0!3m2!1sde!2sde!4v1703610781527!5m2!1sde!2sde" className='w-full h-[300px]' title='Google Maps' allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> */}
                <div className='flex items-center justify-start mt-[20px] gap-x-[12px]'>
                    <IoLocation size={25} />
                    <p className='mt-[2px]'>Rühlweg 34,<br />45470 Mülheim an der Ruhr</p>
                </div>
                <a href='mailto:info@mysave.de' className='flex items-center justify-start mt-[20px] gap-x-[12px]'>
                    <IoMail size={25} />
                    <p className='mt-[2px]'>info@mysave.de</p>
                </a>
                <a href='tel:0049020899840585' className='flex items-center justify-start mt-[20px] gap-x-[12px]'>
                    <IoCall size={25} />
                    <p className='mt-[2px]'>+49 020899840585</p>
                </a>

            </div>

            <div className='w-full max-[1024px]:h-auto h-[550px] min-[1024px]:border-r border-[#ddd] dark:border-[dimgrey] min-[1024px]:pr-[20px]'>
            
                <p className='text-center font-[roboto-bold] mb-[10px] mt-[15px] text-[25px]'>Unternehmen</p>

                { footerNavigationsData.map((navigation, index)=>{
                    return (
                        <Link key={index} to={navigation.to} className='w-full h-[50px] flex items-center justify-center bg-[whitesmoke] dark:bg-[rgba(0,0,0,0.3)] mt-[20px] rounded-full'>
                            { navigation.title }
                        </Link>
                    )
                }) }

            </div>
           
            <div className='w-full max-[1024px]:h-auto h-[550px]'>
            
                <p className='text-center font-[roboto-bold] mb-[10px] mt-[15px] text-[25px]'>Social-Medien</p>

                { social_media.map((navigation, index)=>{
                    return (
                        <a key={index} href={navigation.href} className='w-full h-[50px] flex items-center justify-center bg-[whitesmoke] dark:bg-[rgba(0,0,0,0.3)] mt-[20px] rounded-full gap-x-[10px] pl-[5px]'>
                            { navigation.icon }
                            <p className='w-[72.14px] text-center'>{ navigation.title }</p>
                        </a>
                    )
                }) }

            </div>

        </div>
    </div>

    <div className='w-full h-[100px] bg-white dark:bg-[#212121] border-t border-[#ddd] dark:border-[dimgrey] px-[8%] max-[1024px]:px-[20px] max-[1024px]:flex-col-reverse flex items-center justify-between max-[1024px]:justify-center gap-y-[5px] gap-x-[15px]'>
        
         <a href='https://www.vipsdesign.com' className='flex items-center justify-between gap-x-[8px]'>
            <img src={vipsdesign} alt="vipsdesign" width={35} height={25} className='mt-[2px]' />
            <p className='dark:text-[lightgrey]'>POWERED BY vipsdesign.com</p>
         </a>
        
        <p className='text-[15px]'>©Copyright by Mysave.de { new Date().getFullYear() }</p>
    </div>

    </>
  )
}
