import { toast } from "react-toastify";
import { BACKEND_PHP_URL } from "../env"

const post = async ({ api, type = "json", values, auth = false })=>{

   let formData = new FormData();

   formData.append("action", "true")

   if(auth){
      formData.append("token", localStorage.getItem("token") || false)
   }

   for(let key in values){

      if(values[key] instanceof File){

         let newFileName = values[key].name.replace(/[^a-zA-Z0-9-_\.]/g, '');

         formData.append(key, values[key], newFileName)

      }else {
         formData.append(key, typeof values[key] === "string" ? values[key].trim() : values[key])
      }
      
   } 

   return await fetch(`${BACKEND_PHP_URL}${api}`, {
      method: "post",
      body: formData
   })
   .then(res => res[type]())
   .then(data => {
         return data
   })
   .catch(e => {
      toast.error("Ein Systemfehler ist aufgetreten")
   })

}

export {
    post
}