import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import emailValidation from '../../../validations/auth/forgot-password-validation'
import codeValidation from '../../../validations/auth/verification-code-validation'
import resetValidation from '../../../validations/auth/reset-password-validation'
import logoLight from "../../../../public/mysave-light.webp"
import logoDark from "../../../../public/mysave-dark.webp"
import { post } from "../../../utils/http_fetch"
import { useDarkMode } from "../../../stores"
import { toast } from 'react-toastify';

export default function ForgotPassword() {

  const { dark } = useDarkMode()

  const [correctEmail, setCorrectEmail] = useState("");
  const [correctCode, setCorrectCode] = useState("");

  const [page, setPage] = useState("email");

  const navigate = useNavigate() 

  const forgotPwd = (values, setCorrectEmail, setPage)=>{
    post({
        api: "web/auth/forgot-password",
        values,
        type: "json"
    })
    .then(data => {
        if(data?.success){
            toast.success("E-Mail erfolgreich gesendet.")
            setCorrectEmail(values?.email)
            setPage("code")
        }else {
            setPage("email")
            toast.error(data?.msg)
        }
    })
}

const pwdCodeVerification = (values, setCorrectCode, setPage)=>{
    post({
        api: "web/auth/password-code-verification",
        values,
        type: "json"
    })
    .then(data => {
        if(data?.success){
            toast.success("Bestätigungs-Code stimmt überein.")
            setCorrectCode(values?.code)
            setPage("reset")
        }else {
            setPage("code")
            toast.error(data?.msg)
        }
    })
}

const resetPwd = (values)=>{
    post({
        api: "web/auth/reset-password",
        values,
        type: "json"
    })
    .then(data => {
        if(data?.success){
            toast.success("Passwort wurde erfolgreich geändert.")
            navigate("/")
        }else {
            if(data.msg === "Leider keinen Passenden Benutzer gefunden."){
                navigate("/")
            }
            toast.error(data?.msg)
        }
    })
}

  return (
   <>

   <div className='w-full max-[1024px]:h-auto flex items-start justify-center px-[20px]'>
      <div className='w-[350px] max-[1024px]:w-full mt-[30px]'>

        <img src={dark ? logoDark : logoLight} alt="logo" className='w-[150px] mx-auto mb-[20px]' />
 
        { page === "email" && (
          <>
          <p className='text-[28px] text-center font-[raleway] font-bold mt-[30px]'>Passwort vergessen</p>

          <Formik
            validationSchema={emailValidation}
            initialValues={{ email: "" }}
            onSubmit={values => {
              forgotPwd(values, setCorrectEmail, setPage)
            }}
          >
            {({ errors, touched, isValid, dirty }) => (
              <Form>
              
              <Field type="text" placeholder='E-Mail-Adresse *' className='input' name='email' />
  
              <ErrorMessage component={"div"} className='text-black dark:text-white mt-[15px]' name='email' />
  
              <button type='submit' disabled={!dirty || !isValid} className='w-full h-[50px] bg-[var(--primary)] text-white rounded-[5px] mt-[20px] disabled:opacity-50 disabled:cursor-not-allowed'>E-Mail Senden</button>

              <Link to={"/auth/login"} className='w-full h-[50px] bg-[whitesmoke] dark:bg-[rgba(0,0,0,0.3)] flex items-center justify-center mt-[15px] rounded'>
                Login
              </Link>

            </Form>
            )}
          </Formik>

          </>
        ) }

        { page === "code" && (
          <>
           
          <p className='text-[28px] text-center font-[raleway] font-bold mt-[30px]'>Code eingeben</p>

          <Formik
            validationSchema={codeValidation}
            initialValues={{ code: "" }}
            onSubmit={values => {
              pwdCodeVerification({ ...values, email: correctEmail }, setCorrectCode, setPage)
            }}
          >
            {({ errors, touched, isValid, dirty }) => (
              <Form>
              
              <Field type="text" placeholder='Code *' className='input' name='code' />

              <ErrorMessage component={"div"} className='text-black dark:text-white mt-[15px]' name='code' />
  
              <button type='submit' disabled={!dirty || !isValid} className='w-full h-[50px] bg-[var(--primary)] text-white rounded-[5px] mt-[20px] disabled:opacity-50 disabled:cursor-not-allowed'>Code bestätigen</button>
            
              <div className='w-full h-[50px] bg-[whitesmoke] dark:bg-[rgba(0,0,0,0.3)] flex items-center justify-center mt-[15px] rounded' onClick={()=>forgotPwd({ email: correctEmail }, setCorrectEmail, setPage)}>E-Mail nochmal Senden</div>

            </Form>
            )}
          </Formik>

          </>
        ) }

        { page === "reset" && (
          <>
           
          <p className='text-[28px] text-center font-[raleway] font-bold mt-[30px]'>Passwort zurücksetzen</p>

          <Formik
            validationSchema={resetValidation}
            initialValues={{ password: "", password2: "" }}
            onSubmit={values => {
              resetPwd({ ...values, code: correctCode, email: correctEmail })
            }}
          >
            {({ errors, touched, isValid, dirty }) => (
              <Form>
              
              <Field type="password" placeholder='Passwort *' className='input' name='password' />

              <ErrorMessage component={"div"} className='text-black dark:text-white mt-[15px]' name='password' />

              <Field type="password" placeholder='Passwort wiederholen *' className='input' name='password2' />

              <ErrorMessage component={"div"} className='text-black dark:text-white mt-[15px]' name='password2' />
  
              <button type='submit' disabled={!dirty || !isValid} className='w-full h-[50px] bg-[var(--primary)] text-white rounded-[5px] mt-[20px] disabled:opacity-50 disabled:cursor-not-allowed'>Zurücksetzen</button>
            
            </Form>
            )}
          </Formik>

          </>
        ) }

      </div>
    </div>

   </>
  )
}
