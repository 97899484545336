import React, { useEffect, useState } from 'react'

export default function primaryStore() {

    const [primary, setPrimary] = useState("#dd00b8")

    // #dd00b8

    useEffect(()=>{
        document.documentElement.style.setProperty("--primary", primary)
    }, [primary])

    return {
        primary,
        setPrimary
    }

}
