import CryptoJS from "crypto-js";

const key = CryptoJS.enc.Hex.parse('0123456789abcdef0123456789abcdef');
const iv  = CryptoJS.enc.Hex.parse('abcdef9876543210abcdef9876543210');

const encrypt = (products)=>{

    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(products), key, { iv: iv });
    const encrypted_data = encrypted.ciphertext.toString(CryptoJS.enc.Base64);

    return encrypted_data
    
}

const decrypt = (products)=>{

    const decrypted = CryptoJS.AES.decrypt(products, key, { iv: iv });
    const decrypted_data = decrypted.toString(CryptoJS.enc.Utf8)

    try {
        return JSON.parse(decrypted_data)
    }catch (e){
        return []
    }
    
}

export {
    encrypt,
    decrypt
}