import React from 'react'
import { useModal } from '../stores'
import modals from '../routes/modals'

export default function Modals() {

  const { openedModals, closeModal, destroyModals } = useModal()

  return (
    <>

        { openedModals.length > 0 && (    
            <div className='w-full h-full bg-[rgba(0,0,0,0.7)] fixed top-0 left-0 z-[15] flex items-center justify-center'>
                { openedModals.map((modal, index)=>{

                    const currentModal = modals.find(m => m.name === modal.name)

                    return (
                      <div key={index} className='w-full hidden last:flex items-center justify-center'>
                       <currentModal.element 
                         data={modal.data}
                         close={closeModal} 
                         destroy={destroyModals} 
                       />
                      </div>
                    )
                }) }
            </div>
        ) }

    </>
  )
}
