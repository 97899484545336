import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import logoLight from "../../../../../public/mysave-light.webp"
import logoDark from "../../../../../public/mysave-dark.webp"
import { useAuth, useCategories, useDarkMode, useModal } from '../../../../stores'
import { IoClose, IoEyedropOutline, IoLogOutOutline, IoMoonOutline, IoPersonCircleOutline, IoSunnyOutline } from 'react-icons/io5'
import { IoBag, IoBagOutline, IoEllipse, IoEllipseOutline, IoFlash, IoFlashOutline, IoGiftOutline, IoRibbon, IoRibbonOutline, IoWomanOutline } from "react-icons/io5"
import { GiBigDiamondRing, GiPearlNecklace, GiHeartEarrings, GiEmeraldNecklace } from "react-icons/gi";
import { BsPercent } from "react-icons/bs";

const getIconComponent = (iconName) => {
  switch (iconName) {
      case 'GiBigDiamondRing':
          return <GiBigDiamondRing size={23} />;
      case 'IoRibbonOutline':
          return <IoRibbonOutline size={23} />;
      case 'IoFlashOutline':
          return <IoFlashOutline size={23} />;
      case 'IoGiftOutline':
          return <IoGiftOutline size={23} />;
      case 'GiPearlNecklace':
          return <GiPearlNecklace size={23} />;
      case 'GiEmeraldNecklace':
          return <GiEmeraldNecklace size={23} />;
      case 'GiHeartEarrings':
          return <GiHeartEarrings size={23} />;
      case 'BsPercent':
          return <BsPercent size={23} />;
      default:
          return null;
  }
}

export default function MenuMobile({ menuVisible, setMenuVisible }) {


  const { user, logout } = useAuth()
  const { categories } = useCategories()
  const { dark, setDark } = useDarkMode()
  const { createModal } = useModal()

  return (
    <>
      <div className={`w-full h-full bg-[rgba(0,0,0,0.7)] fixed top-0 left-0 z-[11] min-[1024px]:hidden duration-500 ${ menuVisible ? 'visible' : 'invisible opacity-0' }`} onClick={()=>setMenuVisible(false)}></div>
      <div className={`w-[300px] h-full bg-white dark:bg-[#1c1c1c] fixed top-0 duration-300 ${ menuVisible ? 'left-0' : 'left-[-300px]' } z-[12] min-[1024px]:hidden overflow-y-auto pb-[150px]`}>
        <div className='w-full h-[80px] bg-white dark:bg-[#1c1c1c] shadow-[0_0_7px_rgba(0,0,0,0.2)] dark:shadow-[0_0_7px_rgba(0,0,0,0.8)] flex items-center justify-between px-[15px] sticky top-0 z-[5]'>
            <Link to="/" onClick={()=>setMenuVisible(false)}>
                <img src={dark ? logoDark : logoLight} alt="logo" width={120} height={24} />
            </Link>
            <div className='w-[40px] h-[40px] rounded-full bg-[whitesmoke] dark:bg-[#333333] flex items-center justify-center cursor-pointer duration-500 hover:bg-[var(--primary)] hover:text-white' onClick={()=>setMenuVisible(false)}>
                <IoClose size={20} />
            </div>
        </div>

        { !user ? (
          <Link to={"/auth/login"} className='w-full h-[58px] cursor-pointer flex items-center justify-start gap-x-[15px] pl-[15px] border-b-[1px] border-[#ddd] dark:border-[dimgrey] mt-[1px] hover:text-[var(--primary)] relative group' onClick={()=>setMenuVisible(false)}>
            <div className='w-[25px]'>
              <IoPersonCircleOutline size={27} /> 
            </div>
            <p>Einloggen</p>
            <div className='w-0 h-[1px] absolute bottom-0 left-0 duration-300 bg-[var(--primary)] group-hover:w-full'></div>
          </Link>
        ) : (
            <div className='w-full h-[58px] cursor-pointer flex items-center justify-start gap-x-[15px] pl-[15px] border-b-[1px] border-[#ddd] dark:border-[dimgrey] mt-[1px] hover:text-[var(--primary)] relative group' onClick={()=>{ logout(); setMenuVisible(false) }}>
              <div className='w-[25px]'>
                <IoLogOutOutline size={27} /> 
              </div>
              <p>Abmelden</p>
              <div className='w-0 h-[1px] absolute bottom-0 left-0 duration-300 bg-[var(--primary)] group-hover:w-full'></div>
           </div>
        ) }

          <div className='w-full h-[58px] cursor-pointer flex items-center justify-start gap-x-[15px] pl-[15px] border-b-[1px] border-[#ddd] dark:border-[dimgrey] hover:text-[var(--primary)] relative group' onClick={()=>{setDark(!dark); setMenuVisible(false)}}>
            <div className='w-[25px]'>
              { dark ? <IoSunnyOutline size={23} /> : <IoMoonOutline size={22} /> }
            </div>
            <p>{ dark ? "Hellansicht" : "Dunkelansicht" }</p>
            <div className='w-0 h-[1px] absolute bottom-0 left-0 duration-300 bg-[var(--primary)] group-hover:w-full'></div>
          </div>

          <div className='w-full h-[58px] cursor-pointer flex items-center justify-start gap-x-[15px] pl-[15px] border-b-[1px] border-[#ddd] dark:border-[dimgrey] hover:text-[var(--primary)] relative group' onClick={()=>{setMenuVisible(false); createModal("main.primary-switcher")}}>
            <div className='w-[25px]'>
              <IoEyedropOutline size={23} />
            </div>
            <p>Farbe auswählen</p>
            <div className='w-0 h-[1px] absolute bottom-0 left-0 duration-300 bg-[var(--primary)] group-hover:w-full'></div>
          </div>

            { categories.map((category, index)=>{
              return (
                <div key={index}>

                  { category.title === "Geschenke" && (
                    <div className='h-[70px] border-b border-[#ddd] dark:border-[dimgrey] flex items-center justify-start pl-[15px]'>
                      <p className='text-[20px] font-[roboto-bold]'>Alle Kategorien</p>
                    </div>
                  ) }

                  <NavLink to={category._to} className={`w-full h-[58px] cursor-pointer flex items-center justify-start gap-x-[15px] pl-[15px] border-b-[1px] border-[#ddd] dark:border-[dimgrey] relative group ${ category.title === "Sale" ? "text-[var(--primary)]" : "hover:text-[var(--primary)]" }`} onClick={()=>setMenuVisible(false)}>
                    { getIconComponent(category.icon) }
                    <p>{ category.title }</p>
                    <div className={`w-0 h-[1px] absolute bottom-0 left-0 duration-300 bg-[var(--primary)] group-hover:w-full`}></div>
                  </NavLink>
                </div>
              )
            }) }

      </div>
    </>
  )
}
