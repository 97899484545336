import React, { useEffect, useState } from 'react'

export default function modalStore() {
  
 const [openedModals, setOpenedModals] = useState([]);

 const createModal = (name, data = {})=>{
    setOpenedModals([...openedModals, {
      name,
      data
    }])
 }
  
 const closeModal = ()=>{
    const copyOfOpenedModals = [...openedModals]
    copyOfOpenedModals.pop()
    setOpenedModals(copyOfOpenedModals)
 }

 const destroyModals = ()=>{
    setOpenedModals([])
 }

 return {
    openedModals,
    createModal,
    closeModal,
    destroyModals
 }

}
