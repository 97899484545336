import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useCart, usePrimary } from '../../../stores';
import Svg from "./svg"
import { IoCheckmarkCircle } from "react-icons/io5"
import ReactConfetti from 'react-confetti';

export default function PaymentSuccess() {
  
  const navigate = useNavigate();
  const { setCart } = useCart();
  const { primary } = usePrimary();
  const location = useLocation();

  const params = new URLSearchParams(location.search)
 
  const clearBasket = params?.get("clear_cart");

  useEffect(()=>{
    
    if(clearBasket){
        setCart([])
    }

    const timeout = setTimeout(()=>{
      navigate('/', {
        replace: true
      })
    }, 10000)

    return ()=>{
      clearTimeout(timeout)
    }

  }, [])

  return (
    <div className='w-full overflow-x-hidden relative'>

      <ReactConfetti
        className='absolute' 
        numberOfPieces={100}
      />
      
      <div className='w-full max-w-[1200px] min-h-[calc(100vh-150px)] max-[1024px]:h-auto max-[550px]:pb-[50px] flex max-[1024px]:flex-col-reverse items-center justify-between mx-auto max-[1024px]:p-[20px] max-[1024px]:pt-[30px] gap-[30px] relative'>

        <div className='w-full h-full flex flex-col items-center justify-center'>
          <IoCheckmarkCircle size={100} color={primary} className='mx-auto' />
          <p className='text-[40px] max-[550px]:text-[30px] font-[roboto-bold] text-center'>Zahlung Erfolgreich</p>
          <p className='text-[18px] mt-[5px] text-center'>Dankeschön für Ihren Einkauf.</p>
          <p className='mt-[5px] text-center'>Bis zum nächstes mal.</p>
          <Link to={"/"} replace className='px-[30px] h-[45px] flex items-center justify-center mt-[20px] rounded-full bg-[var(--primary)] text-white'>Zurück zu Home</Link>
        </div>
        <div className='w-full flex items-center justify-center'>
          <Svg width={400} />
        </div>
      </div>

    </div>
  )
}
