import React, { useEffect, useState } from 'react'

export default function darkModeStore() {
 
    const [dark, setDark] = useState(localStorage.getItem("dark") || false);

    useEffect(()=>{
        if(dark){
            document.documentElement.classList.add("dark")
            localStorage.setItem("dark", true)
        }else {
            document.documentElement.classList.remove("dark")
            localStorage.removeItem("dark")
        }
    }, [dark])  

    return {
        dark,
        setDark
    }

}
