import { createContext, useContext } from "react";
import primaryStore from "./primary";
import darkModeStore from "./dark-mode";
import categoriesStore from "./categories";
import modalStore from "./modal";
import cartStore from "./cart";
import authStore from "./auth";

const Provider = ({ children })=>{

  const data = {
    auth: authStore(),
    primary: primaryStore(),
    darkMode: darkModeStore(),
    categories: categoriesStore(),
    modal: modalStore(),
    cart: cartStore()
  }

  return (
    <WebStore.Provider value={data}>
      { children }
    </WebStore.Provider>
  )
}

const WebStore = createContext();

const useAuth = ()=>( useContext(WebStore).auth )
const usePrimary = ()=>( useContext(WebStore).primary )
const useDarkMode = ()=>( useContext(WebStore).darkMode )
const useCategories = ()=>( useContext(WebStore).categories )
const useModal = ()=>( useContext(WebStore).modal )
const useCart = ()=>( useContext(WebStore).cart )

export {
    WebStore,
    Provider,
    useAuth,
    usePrimary,
    useDarkMode,
    useCategories,
    useModal,
    useCart
}