import React, { useState } from 'react'
import { post } from '../../utils/http_fetch';

export default function authStore() {

    const [user, setUser] = useState(false);

    const getUserInfo = ()=>{
        post({
            api: "web/auth/info",
            values: {
                token: localStorage.getItem("token") || false
            }
        })
        .then(data => {
           if(data.user){
            setUser({
                ...data.user,
                token: localStorage.getItem("token")
            })
           }
        })
    }

    const logout = ()=>{
        setUser(false)
        localStorage.removeItem("token");
    }

    return {
        user, 
        setUser,
        getUserInfo,
        logout
    }

}
